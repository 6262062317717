import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import sanitizeHtml from 'sanitize-html';
import FileDrop from 'react-file-drop';

import IconX from 'react-feather/dist/icons/x';
import IconCheck from 'react-feather/dist/icons/check-square';
import IconLock from 'react-feather/dist/icons/lock';
import IconRepeat from 'react-feather/dist/icons/repeat';

const sanitize = (dirty, options) => ({
	__html: sanitizeHtml(dirty, {options: options})
});

const concatFields = function(object, ...fields){
	let words = [];

	for(let field of fields){
		let value = object[field];
		if(typeof value === 'string' && value.length > 0){
			words.push(value);
		}
	}

	return words.join(' ');
}

const event_opts = { 'event_category': 'TituloValidator' };

function TituloValidator({ titulo_hash }){
	
	const [titulo, setTitulo] = useState(titulo_hash);
	const [loading, setLoading] = useState(false);
	const pushHistory = useRef(true);

	useEffect(() => {
		if (window.performance){
			const timeSincePageLoad = Math.round(performance.now());
			
			window.gtag('event', 'timing_complete', { ...event_opts, 
				name: 'load', value: timeSincePageLoad });
		}
	}, []);

	useEffect( () => {
		window.onpopstate = function(event){
			pushHistory.current = false;
			if(event.state){
				setTitulo(event.state.titulo); }
		}
		
		return () => window.onpopstate = null;
	});

	useEffect( () => {
		if(!pushHistory.current){ 
			pushHistory.current = true;
			return; }

		if(titulo){
			if(titulo.saeko && titulo.saeko.qr_payload){
				window.history.pushState({ titulo }, document.title, `${window.location.pathname}?folio=${titulo.root.folio_control}&qr=${titulo.saeko.qr_payload}`);
			}else{
				window.history.pushState({ titulo }, document.title, `${window.location.pathname}#${titulo.root.folio_control}`);	
			}
		}else{
			window.history.pushState({ titulo: null }, document.title, `${window.location.pathname}`);
		}
		
	}, [titulo]);

	function handleDrop(files, event){
		const file = files[0];
		
		if(file.type != 'text/xml'){
			window.gtag('event', 'errorFileNotXML', event_opts);
			alert("Debe ser un archivo XML");
			return;
		}

		const reader = new FileReader();
		const xhr = new XMLHttpRequest();
		
		xhr.upload.addEventListener("load", function(e){
			// nothing to do...
		}, false);

		xhr.open("POST", "/check");
		xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
		xhr.responseType = 'json';
		
		function uploadError(status){
			setLoading(false);
			window.gtag('event', `uploadError${status}`, event_opts);
		}

		xhr.addEventListener('readystatechange', function(e){
			if(this.readyState === XMLHttpRequest.DONE){
				switch(this.status){
					case 200:
						let new_titulo;

						if(typeof this.response === 'object'){
							new_titulo = this.response;
						}else{
							new_titulo = JSON.parse(this.responseText);
						}
						
						setTitulo(new_titulo);
						setLoading(false);
						break;
					
					case 422:
						alert('El archivo no parece ser un Título Electrónico válido');
						uploadError(this.status);
						break;

					case 415:
						alert('El archivo no tiene un formato XML válido');
						uploadError(this.status);
						break;

					case 413:
						alert('Los Títulos Electrónicos no deberían ser tan grandes');
						uploadError(this.status);
						break;
						
					default:
						uploadError(this.status);
						break;
				}
			}
		});

		reader.onload = function(evt){
			setLoading(true);
			xhr.send(evt.target.result);
			// TODO: validate XML contents
		};
		
		reader.readAsText(file);
	}

	function handleSelect(event){
		handleDrop(event.target.files, event);
	}

	function openFileUpload(){
		window.gtag('event', 'selectFile', event_opts);
		document.getElementById('fileUpload').click();
	}

	function restart(){
		setTitulo(null);
		setLoading(false);
	}


	if(titulo){
		return <TituloResult titulo={titulo} restart={restart}></TituloResult> }


	let filedrop;

	if(!loading){
		filedrop = (<FileDrop onDrop={handleDrop}>
			<div className="large-margin-bottom center-align">
				<p className="margin-bottom subtitle dark-text medium">Valida tu Título Electrónico</p>
				<p className="margin-bottom subtitle">Arrastra aquí un archivo .xml o haz clic para adjuntarlo</p>
				<input id="fileUpload" type="file" onChange={handleSelect} />
				<button className="highlight-bg primary-btn large label waves-effect waves-light" onClick={openFileUpload}>Seleccionar archivo...</button>
			</div>
		</FileDrop>);
	}else{
		filedrop = (<div className="file-drop loading">
			<div className="large-margin-bottom center-align">
				<p className="margin-bottom subtitle dark-text medium">Analizando...</p>
			</div>
		</div>);
	}

	return (
		<div className="container-with-navbar">
			<div className="flex align-self-start flex08">
				<div className="column flex1 main-text">
					<div className="card">
						<div className="row flex1">
							<div className="flex05">
								{filedrop}
							</div>

							<div className="flex05 large-padding-left large-margin-top">
								<div><div className="title large-margin-bottom">
									<span className="highlight-text">Arrastra y listo,</span>
								</div></div>
								
								<p className="dark-text large-margin-left"><IconCheck color="#9CADC6" size="18" /> Revisa los datos que trae tu título electrónico.</p>
								<p className="dark-text large-margin-left"><IconCheck color="#9CADC6" size="20" /> Validamos la firma digital de la autoridad responsable.</p>
								<p className="dark-text large-margin-left"><IconCheck color="#9CADC6" size="20" /> Validamos que el certificado haya sido emitido por un agencia registradora central (SAT y Banco de México).</p>
								<p className="dark-text large-margin-left done"><IconLock color="#6FCF97" size="20" /> Tus datos no serán almacenados ni publicados.</p>
								<p className="dark-text large-margin-left row">
									<a className="highlight-bg primary-btn large label row align-items-center justify-content-center full-width waves-effect waves-light transparent" 
										href="https://www.saeko.io/es/productos/titulacion"
										target="_blank">Más acerca de Titulación Electrónica</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


function SectionItem({ label, value }){
	return (<div className="margin-bottom subtitle">
		<div className="label margin-bottom">{label}</div>
		<div className="dark-text medium">{value}</div>
	</div>);
}

function FieldItem({ label, value }){	
	return (<div className="label large-margin-right">
		<span className="gray-text light">{label}:</span> {value}
	</div>);
}

function FieldItemVertical({ label, value }){
	return (<div className="label margin-bottom">
		<div className="gray-text light">{label}:</div>
		<div>{value}</div>
	</div>);
}

function FirmaCard({ responsable }){
	const [certificateActive, setCertificateActive] = useState(false);

	function toogleCertificado(){
		if(!certificateActive){
			window.gtag('event', 'showResponsable', event_opts); }

		setCertificateActive(!certificateActive);
	}

	
	let signature_valid, certificate, root_valid;

	if(responsable.verified){
		signature_valid = (<div className="row align-items-center">
			<div><div className="check-circle"></div></div>
			<p className="label done margin-left">Firma Digital Verificada</p>
		</div>);
	}else{
		signature_valid = (<div className="row align-items-center">
			<div><div className="alert"></div></div>
			<p className="label danger margin-left">Firma Digital Inválida</p>
		</div>);
	}

	if(responsable.ssl.root_ca){
		root_valid = (<div className="row align-items-center">
			<div><div className="check-circle"></div></div>
			<p className="label done margin-left">Validamos que este vertificado haya sido emitido por:</p>
		</div>);
	}else{
		root_valid = (<div className="row align-items-center">
			<div><div className="alert"></div></div>
			<p className="label danger margin-left">No logramos validar que este vertificado haya sido emitido por:</p>
		</div>);
	}

	return (<div className="card margin-bottom">
		<div className="column align-items-center justify-content-center padding-top">
			<div className="medium body dark-text center-align">
				{concatFields(responsable, 'nombre', 'primer_apellido', 'segundo_apellido')}
			</div>
			<div className="body gray-text margin-bottom center-align margin-bottom">{responsable.cargo}</div>
			<div className="main-text">No. CERTIFICADO</div>
			<div className="gray-text margin-bottom">{responsable.no_certificado_responsable}</div>
			<div className="main-text"><button className="primary-btn transparent" onClick={toogleCertificado}>Ver certificado</button></div>

			{signature_valid}
		</div>
		<FullScreenModal active={certificateActive} onClose={toogleCertificado}>
			<div className="row flex1">	
				<div className="white-bg large-margin-right company-form">
					<div className="column main-text flex6 full-height justify-content-space-between">
						<div><div className="title large-margin-bottom ">
							<span className="dark-text">X.509 Public Key Certificate</span>
						</div></div>

						<div className="large-margin-bottom">
							<FieldItemVertical label="Serial Number" value={responsable.no_certificado_responsable} />
							<FieldItemVertical label="Not Before" value={responsable.ssl.not_before} />
							<FieldItemVertical label="Not After" value={responsable.ssl.not_after} />
						</div>
						

						<div className="row flex1">
							<div className="flex05">
								<div className="margin-bottom subtitle"><span className="dark-text medium">SUBJECT</span></div>

								{responsable.ssl.subject.map( (subject, index) => 
									<FieldItemVertical key={index} label={subject[0]} value={subject[1]} /> )}
							</div>

							<div className="flex05">
								<div className="margin-bottom subtitle"><span className="dark-text medium">ISSUER</span></div>
								
								{root_valid}

								{responsable.ssl.issuer.map( (issuer, index) => 
										<FieldItemVertical key={index} label={issuer[0]} value={issuer[1]} /> )}
							</div>
						</div>
					</div>
				</div>
			</div>
		</FullScreenModal>
	</div>);
}



function TituloResult({ titulo, restart }){
	
	useEffect( () => {
		if(titulo){
			if(titulo.saeko && titulo.saeko.qr_payload){
				window.gtag('event', 'showTituloFromQRCode', event_opts); 
			}else{
				window.gtag('event', 'showTitulo', event_opts);
			}
		}
	}, [titulo]);

	function openXML(){
		window.open(titulo.saeko.xml_url, 'tituloXML');
	}

	let saeko;

	if(titulo.saeko){
		let logo;

		if(titulo.saeko.school_logo_url){
			let logoStyle = { backgroundImage: `url(${titulo.saeko.school_logo_url})` };
			logo = (<div className="main-text margin-bottom">
				<div className="circle-img big light-bg avatar" style={logoStyle}></div>
			</div>);
		}

		saeko = (<div className="card margin-bottom">
			<div className="column align-items-center justify-content-center padding-top">
				{logo}

				<div className="main-text"><button className="primary-btn transparent" onClick={openXML}>Descargar XML</button></div>

				<div className="row align-items-center">
					<div><div className="check-circle"></div></div>
					<p className="label done margin-left">Generado desde Saeko&copy;</p>
				</div>
			</div>
		</div>);
	}

	function servicioSocialTitle(cumplio_servicio_social){
		if(cumplio_servicio_social === '1'){
			return 'CUMPLIDO';
		}else{
			return 'NO APLICA';
		}
	}

	return (<div className="container-with-navbar">
		
		<div className="row flex1 large-margin-bottom">
			<div className="margin-right flex02">
				<button className="highlight-bg primary-btn label row align-items-center justify-content-center full-width waves-effect waves-light" type="button" onClick={restart}>
					<span className="flex margin-right"><IconRepeat color="#FFFFFF" size="16" /></span>Valida otro título
				</button>
			</div>
		</div>

		<div className="row flex1">
			
			<div className="margin-right flex02">
				<div className="column">
					
					{saeko}

					{(titulo.responsables.length ? titulo.responsables.map( (responsable) => 
						<FirmaCard key={responsable.no_certificado_responsable} responsable={responsable} /> ) : <div className="card"><small>Sin Firma Digital</small></div>)}

				</div>
			</div>

			<div className="align-self-start flex08">
				<div className="main-text">
					<div className="card">
						
						<div className="large-margin-bottom">
							<SectionItem label="Institución" value={titulo.institucion.nombre_institucion}/>
							
							<div className="column">
								<div className="inline-flex">
									<FieldItem label="Clave" value={titulo.institucion.cve_institucion} />
								</div>
							</div>
						</div>

						<div className="large-margin-bottom">
							<SectionItem label="Profesionista" value={concatFields(titulo.profesionista, 'nombre', 'primer_apellido', 'segundo_apellido')}/>
							
							<div className="column">
								<div className="inline-flex">
									<FieldItem label="CURP" value={titulo.profesionista.curp} />
									<FieldItem label="Email" value={titulo.profesionista.correo_electronico} />
								</div>
							</div>
						</div>

						<div className="large-margin-bottom">
							<SectionItem label="Carrera" value={titulo.carrera.nombre_carrera}/>
							
							<div className="column">
								<div className="inline-flex margin-bottom">
									<FieldItem label="Periodo" value={titulo.carrera.fecha_inicio + ' a ' + titulo.carrera.fecha_terminacion} />
								</div>
								<div className="inline-flex">
									<FieldItem label="Clave" value={titulo.carrera.cve_carrera} />
									<FieldItem label={titulo.carrera.autorizacion_reconocimiento} value={titulo.carrera.numero_rvoe} />
								</div>
							</div>
						</div>

						<div className="large-margin-bottom">
							<SectionItem label="Folio" value={titulo.root.folio_control}/>
							
							<div className="column">
								<div className="inline-flex margin-bottom">
									<FieldItem label="Fecha de Expedición" value={titulo.expedicion.fecha_expedicion} />
									<FieldItem label="Entidad Federativa" value={titulo.expedicion.entidad_federativa} />
									<FieldItem label="Modalidad" value={titulo.expedicion.modalidad_titulacion} />
								</div>
								<div className="inline-flex">
									<FieldItem label="Fecha de Examen Profesional" value={titulo.expedicion.fecha_examen_profesional} />
									<FieldItem label="Fecha de Exención de Examen Profesional" value={titulo.expedicion.fecha_exencion_examen_profesional} />
								</div>
							</div>
						</div>


						<div className="large-margin-bottom">
							<SectionItem label="Servicio Social" value={servicioSocialTitle(titulo.expedicion.cumplio_servicio_social)}/>

							<div className="column">
								<div className="inline-flex">
									<FieldItem label="Fundamento Legal" value={titulo.expedicion.fundamento_legal_servicio_social} />
								</div>
							</div>
						</div>

						<div className="large-margin-bottom">
							<SectionItem label="Antecedente" value={titulo.antecedente.institucion_procedencia}/>
							
							<div className="column">
								<div className="inline-flex margin-bottom">
									<FieldItem label="Periodo" value={titulo.antecedente.fecha_inicio + ' a ' + titulo.antecedente.fecha_terminacion} />
								</div>
								<div className="inline-flex">
									<FieldItem label="Tipo de Estudio" value={titulo.antecedente.tipo_estudio_antecedente} />
									<FieldItem label="Entidad Federativa" value={titulo.antecedente.entidad_federativa} />
								</div>
							</div>
						</div>
						

					</div>
				</div>
			</div>
		</div>
	</div>);
}

function FullScreenModal({ active, children, onClose }){
	return (<div className="hidden-modal">
		<div className={"fullscreen-modal row" + (active ? ' active' : '')}>
			<div className="flex flex1 padded-screen">
			</div>
			<div className="column flex6">
				{children}
			</div>
			<div className="flex flex1 padded-screen justify-content-flex-end">
				<div className="fixed">
					<div className="circle-btn circle-img clickable" onClick={onClose}>
						<IconX color="#9CADC6" size="20" />
					</div>
				</div>
			</div>
		</div>
	</div>);
}

export default TituloValidator;
